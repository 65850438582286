<template>
  <v-container fluid class="pa-0">
    <v-img
      style="align-items: center"
      :max-height="$vuetify.breakpoint.mdAndUp ? '480' : '260'"
      :src="
        $vuetify.breakpoint.mdAndUp
          ? require('@/assets/images/AboutUsPage/TopMain.png')
          : require('@/assets/images/AboutUsPage/TopMain_Mobile.png')
      "
    >
      <v-row style="max-width: 1080px" class="mx-auto my-12">
        <v-col cols="1" />
        <v-col cols="11">
          <div
            class="font-weight-medium white--text"
            :class="$vuetify.breakpoint.smAndDown ? 'title' : 'headline'"
          >
            메이드올은 온오프라인 <br />메이커 문화를 만들어 갑니다.
          </div>
        </v-col>
      </v-row>
    </v-img>

    <v-container fluid class="pt-5 pa-0">
      <v-row
        style="max-width: 1080px"
        class="mx-auto"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-2 mb-10' : 'mt-15 mb-15'"
      >
        <v-col cols="12" sm="3" md="2">
          <div
            class="font-weight-bold pl-3"
            :class="$vuetify.breakpoint.smAndDown ? 'title' : 'headline'"
          >
            MADE ALL
          </div>
        </v-col>
        <v-col cols="12" sm="9" md="10">
          <div
            class="font-weight-medium"
            :class="$vuetify.breakpoint.smAndDown ? 'body-1' : 'subtitle-1'"
          >
            (주)메이드올은 청년창업기업으로 2015년부터 활동을 시작하여
            3D프린터와 관련된 다양한 분야로의 진출을 거듭하고 있습니다.<br />
            3D프린터 시장 초기에 뛰어들어 뛰어난 기술력과 시장 분석력을 앞세워
            3D프린터를 이용한 시제품 제조 서비스로 시작하여, 3D프린터의
            자체개발, 메이커교육까지 다방면으로 성장하고 있습니다.<br />
            앞으로 4차 산업시대가 우리 삶에 더욱 스며들며, 개인화된 사회가 한
            발짝 더 다가올 것입니다. 모든 단순 업무가 자동화되며, 뛰어난
            창의력과 사고력이 개인의 경쟁력이 되는 시대가 올 것입니다. 단순히
            3D프린터를 산업 시장에서 사용하는 것 뿐만 아닌, 다품종 소량생산의
            3D프린터 제조 특성을 활용하여 학생들의 창의 사고력, 문제해결능력,
            공간지각력 증진을 위해 활용될 수 있습니다.<br />
            국내 메이커 문화의 활성화를 위해 메이드올이 최선을 다하겠습니다.
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="smAndUp">
      <v-row style="max-width: 1080px" class="mx-auto">
        <v-img
          style="align-items: center"
          :src="
            $vuetify.breakpoint.mdAndUp
              ? require('@/assets/images/AboutUsPage/MissionVisionCore.png')
              : require('@/assets/images/AboutUsPage/MissionVisionCore_Mobile.png')
          "
        />
      </v-row>
      <v-row style="max-width: 820px" class="mx-auto mb-15">
        <v-col cols="4">
          <v-card class="transparent text-center" flat>
            <v-card-title style="display: contents !important">
              <div
                :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'body-2'"
              >
                미션
              </div>
            </v-card-title>
            <v-card-text class="body-1 black--text mt-3">
              <div>'협동과 혁신'으로 메이커에게 풍요로운 미래를 제공</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="transparent text-center" flat>
            <v-card-title style="display: contents !important">
              <div
                :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'body-2'"
              >
                비전
              </div>
            </v-card-title>
            <v-card-text class="body-1 black--text mt-3">
              <div>인재와 기술을 바탕으로 최고의 제품과 서비스를 창출</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="transparent text-center" flat>
            <v-card-title style="display: contents !important">
              <div
                :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'body-2'"
              >
                핵심가치
              </div>
            </v-card-title>
            <v-card-text class="body-1 black--text mt-3">
              <div>변화하는 미래 제조 산업을 뒷받침할 수 있는 기업</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="background: blue-grey darken-1">
      <v-row style="max-width: 1080px" class="mx-auto">
        <v-col cols="12" md="6">
          <v-card
            class="transparent"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-10'"
            flat
          >
            <v-card-title
              class="white--text font-weight-medium"
              :class="
                $vuetify.breakpoint.smAndDown ? 'mt-2 title' : 'mt-15 headline'
              "
            >
              메이드올은<br />오늘도 새로운 역사를<br />쓰고 있습니다.
            </v-card-title>
            <v-card-subtitle
              class="font-weight-regular"
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'mt-5 subtitle-1'
                  : 'mt-10 title'
              "
            >
              <span class="white--text">네이버 쇼핑 누적 리뷰</span>
              <span class="orange--text">&nbsp;&nbsp;1,250개</span>
              <br />
              <br />
              <span class="white--text">월 평균 작업건</span>
              <span class="orange--text">&nbsp;&nbsp;230건</span>
              <br />
              <br />
              <span class="white--text">누적 방문 고객 수</span>
              <span class="orange--text">&nbsp;&nbsp;4,392명</span>
            </v-card-subtitle>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            class="rounded-xl mb-10 mr-5 ml-5"
            :class="$vuetify.breakpoint.smAndDown ? 'mt-2 pt-3' : 'mt-10 pa-5'"
          >
            <v-list>
              <v-list-group
                v-for="years in years"
                :key="years.title"
                no-action
                :append-icon="null"
              >
                <template v-slot:activator>
                  <v-list-item-content
                    class="font-weight-bold"
                    :class="
                      $vuetify.breakpoint.smAndDown ? 'title' : 'headline'
                    "
                  >
                    <v-row>
                      <v-col cols="12" sm="3" md="2">
                        <v-list-item-title
                          v-text="years.year"
                        ></v-list-item-title>
                      </v-col>
                      <v-col cols="12" sm="9" md="10">
                        <v-list-item-title
                          v-text="years.title"
                        ></v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="child in years.contents" :key="child.title">
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row
        style="max-width: 1080px"
        class="mx-auto"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-15 my-12'"
      >
        <v-col
          cols="12"
          md="3"
          class="text-center font-weight-bold"
          :class="
            $vuetify.breakpoint.smAndDown ? 'headline' : 'display-1 mb-10 '
          "
        >
          Our Team
        </v-col>
        <v-col cols="12" md="9">
          <v-img
            class="mx-auto"
            :src="
              $vuetify.breakpoint.mdAndUp
                ? require('@/assets/images/AboutUsPage/OurTeam.png')
                : require('@/assets/images/AboutUsPage/OurTeam_Mobile.png')
            "
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row
        style="max-width: 1080px"
        class="mx-auto"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-3 mb-10' : 'mt-15 my-12'"
      >
        <v-col
          cols="12"
          sm="3"
          md="4"
          class="text-center font-weight-bold"
          :class="
            $vuetify.breakpoint.smAndDown ? 'headline' : 'display-1 mb-10 '
          "
        >
          Company<br />Profile
        </v-col>
        <v-col
          cols="12"
          sm="9"
          md="8"
          :class="$vuetify.breakpoint.smAndDown ? '' : 'mt-10 '"
        >
          <v-row>
            <v-col cols="12" sm="3" class="text-center"> 사업자등록번호 </v-col>
            <v-col
              cols="12"
              sm="9"
              :class="$vuetify.breakpoint.smAndDown ? 'text-center pa-0' : ''"
            >
              882-87-01886
            </v-col>
            <v-col cols="12" sm="3" class="text-center"> 법인등록번호 </v-col>
            <v-col
              cols="12"
              sm="9"
              :class="$vuetify.breakpoint.smAndDown ? 'text-center pa-0' : ''"
            >
              134511-0474376
            </v-col>
            <v-col cols="12" sm="3" class="text-center">
              통신판매업신고번호
            </v-col>
            <v-col
              cols="12"
              sm="9"
              :class="$vuetify.breakpoint.smAndDown ? 'text-center pa-0' : ''"
            >
              2020-용인수지-1536
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import setMeta from "@/utils/setMeta";

export default {
  components: {},
  name: "AboutUs",
  data: () => ({
    years: [
      {
        year: "2015",
        title: "3D프린터를 활용한 아이디어 제품 판매",
      },
      {
        year: "2016",
        title: "3D프린터 자체개발 착수",
        contents: [
          { title: "200mm급 3D프린터 개발 착수" },
          { title: "3D프린터 출력 외주 서비스 런칭" },
        ],
      },
      {
        year: "2017",
        title: "네이버 스마트스토어 쇼핑몰 오픈",
        contents: [
          { title: "네이버 스마트스토어 쇼핑몰 오픈" },
          { title: "4축 로봇팔 코딩교육키트 펀딩" },
        ],
      },
      {
        year: "2018",
        title: "네이버쇼핑 3D프린터 출력외주 1위 달성",
        contents: [
          { title: "200mm급 3D프린터 출시" },
          { title: "메이드올 개인사업자 설립" },
        ],
      },
      {
        year: "2019",
        title: "MA3D:Standard 제품 출시",
        contents: [
          { title: "경기도 대학생 기술융합 창업지원사업 선정" },
          { title: "MA3D:Standard KC인증" },
          { title: "대구 남구 사무실 오픈" },
          { title: "대륜중학교 3D모델링 강의" },
        ],
      },
      {
        year: "2020",
        title: "MA3D:Mini 듀얼노즐 제품 출시",
        contents: [
          { title: "경기도 대학생 기술융합 창업지원사업 선정" },
          { title: "주식회사 메이드올 설립" },
          { title: "메이드올 대표 홈페이지 제작" },
          { title: "MA3D:Mini KC인증" },
        ],
      },
      {
        year: "2021",
        title: "메이커교육 서비스 진출",
        contents: [
          { title: "경기도 대학생 기술융합 창업지원사업 선정" },
          { title: "메이커교육 컨텐츠 공유 플랫폼 개발" },
          { title: "경기교육나눔사업 강사 선정" },
          { title: "청년 디지털 일자리 지원사업 협약" },
          { title: "2021년형 MA3D:Standard 출시" },
          { title: "대구일마이스터고 산학협력" },
        ],
      },
      {
        year: "2022",
        title: "온라인 시제품 제조 서비스 플랫폼 개발",
        contents: [
          { title: "메이드올 통합 홈페이지 개발" },
          { title: "통합 주문 관리시스템 개발" },
        ],
      },
    ],
  }),
  beforeCreate() {
    setMeta({
      title: "회사 소개",
      description: "메이드올 소개 페이지입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Fmain.png?alt=media&token=d87cafdc-d36d-428c-bc9d-44d66386d74b",
    });
  },
};
</script>

<style></style>
